<script setup lang="ts">
const props = defineProps<{
  clientId: string
  name: string
  rating: number
  date: Date
  content: string
}>()

const { $parseReviewScore } = useNuxtApp()
const timeAgo = useTimeAgo(props.date)
const runtimeConfig = useRuntimeConfig()
</script>

<template>
  <div>
    <div class="flex items-center">
      <BaseAvatar
        size="medium"
        :src="`${runtimeConfig.public.uploadUrl}profile/${clientId}`"
        :user-name="name"
        :alt="`${name} avatar`"
      />
      <div class="ml-2">
        <div class="flex items-center">
          <span class="text-base font-medium leading-5">{{ name }}</span>
          <i class="pi pi-star mx-2 text-gray-200" />
          <span class="text-gray-200">
            {{ $parseReviewScore(rating) }}
          </span>
        </div>
        <div class="text-xs">Published {{ timeAgo }}</div>
      </div>
    </div>
    <div class="mt-4 pr-8 leading-5 text-gray-200">
      {{ content }}
    </div>
  </div>
</template>
