<script setup lang="ts">
defineProps<{
  reviewCount: number
  reviewRating: number
}>()

const { $parseReviewScore } = useNuxtApp()
</script>

<template>
  <h2 class="flex items-center text-2xl">
    Reviews
    <i class="pi pi-star ml-4 mr-2 text-2xl text-blue-200" />
    <span class="mr-4 text-blue-200">
      {{ $parseReviewScore(reviewRating) }}
    </span>
    <span class="text-base"> ({{ reviewCount }}) </span>
  </h2>
</template>
