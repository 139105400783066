<script setup lang="ts">
import type { Social } from 'profile/types/profile'
defineProps<{
  social: Social
  colOnMobile?: boolean
}>()
const { $formatToCompactNumber } = useNuxtApp()
</script>

<template>
  <li
    v-if="social.id"
    class="flex justify-center px-1 py-2 sm:px-2"
  >
    <SocialIconLink
      :platform-id="social.id.toLowerCase()"
      :url="social.url"
      :icon-size="24"
    />
    <div
      class="flex flex-wrap justify-center"
      :class="colOnMobile ? 'max-[400px]:flex-col' : ''"
    >
      <div class="mb-2 px-1 text-center">
        <div class="text-md mb-2 mt-1 font-medium">
          {{ $formatToCompactNumber(social.followersCount || 0) }}
        </div>
        <p class="text-xs tracking-wide">Followers</p>
      </div>
      <div class="px-1 text-center">
        <div class="text-md mb-2 mt-1 font-medium">
          {{ social.engagementRate || '-' }}
        </div>
        <p class="text-xs tracking-wide">Eng. Rate</p>
      </div>
    </div>
  </li>
</template>
