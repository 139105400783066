import type { Reviews } from '@/types/reviews'

export default async (
  productId: string | undefined,
): Promise<Reviews | null> => {
  try {
    if (!productId) {
      return null
    }
    // it is not properly escaped by the backed side, so we need to do it here
    // default approach with ohmyfetch `params` will not work
    const { data, error } = await useApi<Reviews>(
      `reviews?where=target(id%3D%22${productId}%22)&limit=500&offset=0`,
    )

    if (error.value || !data.value) {
      throw new Error('No reviews data found.')
    }

    return data.value
  } catch (error) {
    throw createError({
      statusCode: 404,
      statusMessage: (error as Error).message,
      fatal: true,
    })
  }
}
